import { Link } from 'react-router-dom';
import './Button.css';
import { useEffect, useState } from 'react';
import moment from 'moment-timezone';

export default function Button({    icon = undefined, 
                                    name = "Aucun nom fourni", 
                                    customClassName = "",
                                    onClick = () => {}, 
                                    styleType = "classic", 
                                    color = "_main_white", 
                                    iconPosition = "left",
                                    isLink = false,
                                    linkPath = "/",
                                    onClickTimer = 0,
                                    display = true}) {
    
    const [deadline, setDeadline] = useState(moment.tz("Europe/Paris"));
    const [percent, setPercent] = useState("100%");
    const [timeOutId, setTime0utId] = useState(undefined);
    
    useEffect(() => {
        const final = deadline.valueOf();
        const tmp = moment.tz("Europe/Paris").valueOf();
        if (final <= tmp){
            clearInterval(timeOutId);
            setPercent("100%");
        }
    }, [percent])

    useEffect(() => {
        const final = deadline.valueOf();
        const tmp = moment.tz("Europe/Paris").valueOf();
        if (final > tmp){
            setTime0utId(setInterval(() => {
                const final = deadline.valueOf();
                const tmp = moment.tz("Europe/Paris").valueOf();    
                setPercent(`${100 - ((final - tmp) / onClickTimer) * 100}%`);
            }, 50))
        } 
    },[deadline])

    if (display) {
        if (isLink) {
            return (
                <Link   to={linkPath}
                        target="_blank" 
                        rel="noopener noreferrer">
                    <div    className={`Button ${styleType === "classic" ? "MontSerrat-ExtraBold" : "MontSerrat-Regular"} ${customClassName} ${iconPosition} ${styleType} ${color}`}>
                        {icon !== undefined && icon === "caelus" && icon !== "cirrus" ? <img src={"./caelus-without-background.svg"} alt="Icône représentant le logo du bouton."/> : <></>}
                        {icon !== undefined && icon !== "caelus" && icon !== "cirrus" ? <span className='material-symbols-outlined'>{icon}</span> : <></>}
                        {icon !== undefined && icon !== "caelus" && icon === "cirrus" ? <img src={"./no_bg_logo_uni.svg"} alt="Icône représentant le logo de cirrus."/> : <></>}
                        {name}
                    </div>
                </Link>
            );
        }
        return (
            <>
            <div    className={`Button ${styleType === "classic" ? "MontSerrat-ExtraBold" : "MontSerrat-Regular"} ${customClassName} ${iconPosition} ${styleType} ${color}`}
                    onClick={(e) => {e.preventDefault(); setDeadline(moment.tz("Europe/Paris").isAfter(deadline, "ms") ? moment.tz("Europe/Paris").add(onClickTimer, "ms").clone(): deadline); onClick()}}>
                {icon !== undefined && icon === "caelus" && icon !== "cirrus" ? <img src={"./caelus-without-background.svg"} alt="Icône représentant le logo du bouton."/> : <></>}
                {icon !== undefined && icon !== "caelus" && icon !== "cirrus" ? <span className='material-symbols-outlined'>{icon}</span> : <></>}
                {icon !== undefined && icon !== "caelus" && icon === "cirrus" ? <img src={"./no_bg_logo_uni.svg"} alt="Icône représentant le logo de cirrus."/> : <></>}
                {name}
            </div>
            {onClickTimer > 0 ?
            <div className='ButtonTimer'>
                <div className='salt' style={{width: `${percent}`}}></div>
            </div>:<></>}
            </>
        );
    }
}