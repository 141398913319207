import { useEffect, useState } from 'react';
import Button from '../Button/Button';
import moment from 'moment-timezone';
import 'moment/locale/fr';
import './Calendar.css';
import { useRef } from 'react';
import BlurredBackground from '../BlurredBackground/BlurredBackground';
import Application from '../Application/Application';
import Session from '../Session/Session';

export default function Calendar({beginDate = moment.tz("Europe/Paris"), number_of_weeks = 3, datas = []}) {
    const [weeks, setWeeks] = useState([]);
    const [count, setCount] = useState(0);
    const [isPressing, setPressing] = useState(false);
    const [selected, setSelected] = useState(false);
    const timeoutRef = useRef(null);
    
    useEffect(() => {
        const week = addThisWeek();
        const next = addNextWeeks(number_of_weeks-1);
        const addDatas = parse(week.concat(next));
        setWeeks(addDatas);
    }, []);

    useEffect(() => {
        const week = addThisWeek();
        const next = addNextWeeks(number_of_weeks-1+count);
        const addDatas = parse(week.concat(next));
        setWeeks(addDatas);
    }, [count, datas]);

    const addThisWeek = () => {
        moment.locale("fr");
        let w = [];
        for (let i = 0; i < 7; i++) {
            w.push({date: beginDate.clone().weekday(i)});
        }
        return [w];
    }
 
    const addNextWeeks = (n = 1) => {
        moment.locale("fr");
        const result = [];
        for (let i = 1; i <= n; i++) {
            const tmp = [];
            for (let j = 0; j < 7; j++) {
                tmp.push({date: beginDate.clone().add(i, "week").weekday(j)});
            }
            result.push(tmp);
        }
        return result;
    }

    const handleMouseDown = () => {
        setPressing(true);
        timeoutRef.current = setTimeout(() => {
            setSelected(!selected);
            setCount(count === 0 ? 3 : 0);
        }, 1000);
    }
    const handleMouseUp = () => {
        setPressing(false);
        clearTimeout(timeoutRef.current);
    }

    const parse = (table) => {
        for (let i = 0; i < table.length; i++) {
            for (let j = 0; j < table[i].length; j++) {
                table[i][j].datas = datas.filter(d => moment(d.date_crea).tz("Europe/Paris").isSame(table[i][j].date, "day"));
            }
        }
        return table
    }

    return (
        <>
        <BlurredBackground  display={selected}
                            onClick={() => {setSelected(!selected); setCount(count === 0 ? 3 : 0)}} />
        <div    className="Calendar">
            <div className='title-ctn'>
                <h1>Mon calendrier</h1>
                
            </div> 
            <div    className={selected ? "calendar-ctn selected" : "calendar-ctn"}
                    onMouseDown={handleMouseDown}
                    onMouseUp={handleMouseUp}
                    onMouseLeave={handleMouseUp}
                    onTouchStart={handleMouseDown}
                    onTouchEnd={handleMouseUp}
                    onDoubleClick={() => {setSelected(!selected);setCount(count === 0 ? 3 : 0);}}>
                <div className='head-ctn'>
                    <span className='MontSerrat-ExtraBold'>{moment().weekday(0).format("dd.")}</span>
                    <span className='MontSerrat-ExtraBold'>{moment().weekday(1).format("dd.")}</span>
                    <span className='MontSerrat-ExtraBold'>{moment().weekday(2).format("dd.")}</span>
                    <span className='MontSerrat-ExtraBold'>{moment().weekday(3).format("dd.")}</span>
                    <span className='MontSerrat-ExtraBold'>{moment().weekday(4).format("dd.")}</span>
                    <span className='MontSerrat-ExtraBold'>{moment().weekday(5).format("dd.")}</span>
                    <span className='MontSerrat-ExtraBold'>{moment().weekday(6).format("dd.")}</span>
                </div>
                <div className='divider' />
                {weeks.map((w, key) => {
                    let firstDayDetected = false;
                    let newMonth = false;
                    let firstDayIsAMonday = false;
                    return (
                        <div    className="dates-ctn"
                                key={`Calendar${weeks[key][0].date.format('w')}`}>
                        {w.map((d) => {
                            if (d.date.format("D") === "1") {firstDayDetected = true;}
                            if (d.date.format("D") === "1" && d.date.format("d") === "1") firstDayIsAMonday = true;
                            if (!firstDayIsAMonday) return (
                                <div    className='date-ctn'
                                key={`Calendar${weeks[key][0].date.format('w')}${d.date.format('D')}1`}>
                                    <span   className={firstDayDetected || moment().isAfter(d.date, "day") ? "hide MontSerrat-Regular" : d.date.isSame(moment(), 'day') ? "MontSerrat-ExtraBold" : "MontSerrat-Regular"} >
                                        {d.date.format("D")}
                                    </span>
                                    {d.datas.length !== 0 ?
                                    <span className='datas'>+{d.datas.length}</span>
                                    :<></>}
                                </div>);
                        })}
                        {firstDayDetected ? <span className='full MontSerrat-ExtraBold'>{w[key].date.format("MMMM").charAt(0).toUpperCase()+w[key].date.format("MMMM").slice(1)}</span>: <></>}
                        {firstDayDetected ? w.map((d) => {
                            if (d.date.format("D") === "1") newMonth = true;
                            return (
                                <div    className='date-ctn'
                                        key={`Calendar${weeks[key][0].date.format('w')}${d.date.format('D')}2`}>
                                    <span   className={!newMonth ? "hide MontSerrat-Regular" : d.date.isSame(moment(), 'day') ? "MontSerrat-ExtraBold" : "MontSerrat-Regular"}
                                            >
                                        {d.date.format("D")}
                                    </span>
                                    {d.datas.length !== 0 ?
                                        <span className='datas'>+{d.datas.length}</span>
                                    :<></>}
                                </div>);
                        }):<></>}
                        </div>
                    )
                })}
            </div>
            {datas.filter(s => s.libelle === "Programmée" || s.etat === "Démarre bientôt" ||  s.libelle === "Bientôt prête").length > 0 ?
                <div className='next-ctn'>
                    <div className='title-ctn'>
                        <h1>Prochainement</h1>
                    </div> 
                    {datas.filter(s => s.libelle === "Programmée"  || s.libelle === "Bientôt prête").map((app, key) => {
                        return (
                            <Application    type='liste'
                                            informations={app}
                                            state="planned"
                                            key={`Calendar${key}`}
                                            />
                        )
                    })}
                    {datas.filter(s => s.etat === "Démarre bientôt").map((s, key) => {
                        return (
                            <Session    type='liste'
                                        informations={s}
                                        state={"planned"}
                                        key={`SessionsCalendar${key}`} />
                        )
                    }) }
                    
                </div>:<></>}
        </div>

        
    </>);
}