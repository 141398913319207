import { useEffect, useState } from 'react';
import Input from '../elements/Input/Input';
import './createSession.css';
import environment from '../../services/environment.service';
import etudiants from '../../services/etudiants.service';
import Button from '../elements/Button/Button';
import moment from 'moment-timezone';
import Session from '../elements/Session/Session';
import Calendar from '../elements/Calendar/Calendar';
import sessions from '../../services/sessions.service';

export default function CreateSession({onCreationComplete = () => {}}) {
    const [categories, setCategories] = useState([]);
    const [selectedCategorie, setSelectedCategorie] = useState(-1);
    const [selectedEnvironnement, setSelectedEnvironnement] = useState(-1);
    const [searchValue, setSearchValue] = useState("");
    const [libelle, setLibelle] = useState("");
    const [allApplications, setAllApplications] = useState([]);
    const [loading, setLoading] = useState(true);
    const [alert, setAlert] = useState("");
    const [step, setStep] = useState("main");

    const [searchEtudiant, setSearchEtudiant] = useState("");
    const [allEtudiants, setAllEtudiants] = useState([]);
    const [listEtudiants, setListeEtudiants] = useState([]);

    const [dateDebut, setDateDebut] = useState(undefined);
    const [dateFin, setDateFin] = useState(undefined);
    const [dateCursor, setDateCursor] = useState("begin");

    useEffect(() => {
        setLoading(true);
        environment.lister()
        .then((r) => {
            setCategories(r);
            setLoading(false);
        })
        .catch((err) => {
            console.log(err);
            setLoading(false);
        })
    }, []);

    useEffect(() => {
        setLoading(true);
        etudiants.lister()
        .then((r) => {
            setAllEtudiants(r);
            setLoading(false);
        })
        .catch((err) => {
            console.log(err);
            setLoading(false);
        })
    }, []);

    useEffect(() => {
        const tmp = [];
        for (let i = 0; i < categories.length; i++) {
            for (let j = 0; j < categories[i].environnements.length; j++) {
                if (tmp.filter((apps) => apps.libelle === categories[i].environnements[j].libelle).length === 0)
                    tmp.push(categories[i].environnements[j])
            }
        }
        setAllApplications(tmp);
    }, [categories]);

    const launchCreation = () => {
        setLoading(true);
        sessions.creer({libelle: libelle, id_environnement: selectedEnvironnement, date_crea: dateDebut, date_fin: dateFin, etudiants: listEtudiants}).then((r) => {
            onCreationComplete()
            setLoading(false);
        })
    }

    if (step === "application") {
        return (
            <div className="createSession step">
                    <form   onSubmit={(e) => {e.preventDefault();launchCreation();}}>
                        {alert !== "" ?
                            <div className="alert">
                                
                                <span   className="material-symbols-outlined icon" >warning</span>
                                <span   className='text'>{alert}</span>
                        </div>:<></>}


                        <Input  type='text'
                            name="search"
                            label_color="_text_green_two"
                            input_color="_main_full_white"
                            placeholder="Choisir l'application"
                            value={searchValue}
                            onUpdate={setSearchValue} />

                                
                        <div className="categorie-ctn">
                            {categories.filter((c) => c.libelle.toLowerCase().includes(searchValue.toLowerCase())).map((c, key) => {
                                return (
                                    <div    className="cat-btn-ctn"
                                            key={`Categorie${key}`} >
                                        <Button styleType='chip'
                                                customClassName="categorie"
                                                color={c.id_categorie === selectedCategorie ? '_main_green_three' : '_main_green_two'}
                                                name={c.libelle}
                                                onClick={() => {setSelectedCategorie(selectedCategorie === c.id_categorie ? -1 : c.id_categorie)}}
                                                />
                                        {selectedCategorie === c.id_categorie ? 
                                        c.environnements.map((e, ekey) => {
                                            return (
                                                <Button styleType='chip'
                                                        customClassName="environnement"
                                                        color={selectedEnvironnement === e.id_environnement ? '_main_green_three' : '_main_full_grey'}
                                                        name={e.libelle}
                                                        onClick={() => {setSelectedEnvironnement(selectedEnvironnement === e.id_environnement ? -1 : e.id_environnement);setStep(dateDebut !== undefined && dateFin !== undefined ? "home" : "date-debut")}}
                                                        key={`Categorie${key}-${ekey}`} />
                                            );
                                        })
                                        : <></>}
                                        
                                    </div>
                                    
                                );
                            })}
                            {selectedCategorie === -1 && searchValue !== "" ? 
                            allApplications.filter((apps) => apps.libelle.toLowerCase().includes(searchValue.toLowerCase())).map((apps, key) => {
                                return (
                                    <Button styleType='chip'
                                            customClassName="environnement"
                                            color={selectedEnvironnement === apps.id_environnement ? '_main_green_three' : '_main_full_grey'}
                                            name={apps.libelle}
                                            onClick={() => {setSelectedEnvironnement(selectedEnvironnement === apps.id_environnement ? -1 : apps.id_environnement)}}
                                            key={`Apps${key}`} />
                                );
                            })
                            :<></>}
                        </div>
                        
                            
                        <Button icon={"close"}
                                name={"Retourner en arrière"}
                                onClick={() => {setStep("home")}}
                                customClassName='back'
                                iconPosition='right'
                                styleType='chip'
                                color='_main_full_white'/>
                    </form>
            </div>
        );
    }
    else if (step === "date-debut" || step === "date-fin") {
        return (
                <div className="createSession step">
                    <form   onSubmit={(e) => {e.preventDefault();launchCreation();}}>
                        {alert !== "" ?
                            <div className="alert">
                                
                                <span   className="material-symbols-outlined icon" >warning</span>
                                <span   className='text'>{alert}</span>
                        </div>:<></>}
                        <div className="dates-form-ctn step">
                            <p>Dates et durée</p>
                            <div className='dates-ctn'>
                                <Button name={dateDebut === undefined ? "Début" : dateDebut.format("DD/MM HH[h]mm")}
                                        customClassName={dateCursor === "begin" ? 'selected' : 'not-selected'}
                                        onClick={() => {setDateCursor("begin")}}/>
                                <p>à</p>
                                <Button name={dateFin === undefined ? "Fin" : dateFin.format("DD/MM HH[h]mm")}
                                        customClassName={dateCursor === "end" ? 'selected' : 'not-selected'}
                                        onClick={() => {setDateCursor("end")}}/>
                            </div>
                            
                        </div>

                        <div className='btn-ctn'>
                            {dateCursor === "begin" ?
                            <Button name={"Maintenant"}
                                    onClick={() => {setDateDebut(moment.tz("Europe/Paris")); setDateCursor("end");}}
                                    styleType='chip'
                                    customClassName='back'/>:<></>}
                            {dateCursor === "begin" ?
                            <Button name={moment.tz("Europe/Paris").add(1, 'h').startOf('hour').format("HH[h]mm")}
                                    onClick={() => {setDateDebut(moment.tz("Europe/Paris").add(1, 'h').startOf('hour')); setDateCursor("end");}}
                                    styleType='chip'
                                    customClassName='back'/>:<></>}
                            {dateCursor === "end" ?
                            <Button name="30min"
                                    onClick={() => {setDateFin(moment(dateDebut).tz("Europe/Paris").clone().add(30, 'minute')); setDateCursor("begin"); setStep(listEtudiants.length === 0 ? "utilisateurs" : "home");}}
                                    styleType='chip'
                                    customClassName='back'/>:<></>}
                            {dateCursor === "end" ?
                            <Button name="1h"
                                    onClick={() => {setDateFin(moment(dateDebut).tz("Europe/Paris").clone().add(1, 'h')); setDateCursor("begin");setStep(listEtudiants.length === 0 ? "utilisateurs" : "home");}}
                                    styleType='chip'
                                    customClassName='back'/>:<></>}
                            {dateCursor === "end" ?
                            <Button name="2h"
                                    onClick={() => {setDateFin(moment(dateDebut).tz("Europe/Paris").clone().add(2, 'h')); setDateCursor("begin");setStep(listEtudiants.length === 0 ? "utilisateurs" : "home");}}
                                    styleType='chip'
                                    customClassName='back'/>:<></>}
                            {dateCursor === "end" ?
                            <Button name="3h"
                                    onClick={() => {setDateFin(moment(dateDebut).tz("Europe/Paris").clone().add(3, 'h')); setDateCursor("begin");setStep(listEtudiants.length === 0 ? "utilisateurs" : "home");}}
                                    styleType='chip'
                                    customClassName='back'/>:<></>}
                        </div>

                        {/*<Calendar beginDate={moment.tz("Europe/Paris")}/>*/}

                        <Button icon={"close"}
                                name={"Retourner en arrière"}
                                onClick={() => {setStep("home")}}
                                customClassName='back'
                                iconPosition='right'
                                styleType='chip'
                                color='_main_full_white'/>
                    </form>
            </div>
        );
    }
    else if (step === "utilisateurs") {
        return (
                <div className="createSession step">
                    <form   onSubmit={(e) => {e.preventDefault();launchCreation();}}>
                        {alert !== "" ?
                            <div className="alert">
                                
                                <span   className="material-symbols-outlined icon" >warning</span>
                                <span   className='text'>{alert}</span>
                        </div>:<></>}
                        <div className="users-form-ctn step">
                            <Input  type='text'
                                name="name"
                                label_color="_text_green_two"
                                input_color="_main_full_white"
                                placeholder="Ajouter des étudiants (optionnel)"
                                value={searchEtudiant}
                                onUpdate={setSearchEtudiant}/>
                            <div className='available'>
                                {searchEtudiant.length !== 0 ?
                                    allEtudiants.filter((e) => (e.mail.toLocaleLowerCase().includes(searchEtudiant.toLocaleLowerCase()) || e.prenom.toLocaleLowerCase().includes(searchEtudiant.toLocaleLowerCase()) || e.nom.toLocaleLowerCase().includes(searchEtudiant.toLocaleLowerCase())) && listEtudiants.filter(le => le.mail === e.mail).length === 0)
                                    .map((e, key) => {
                                        return (
                                            <div className='available-student-ctn'>
                                                <p key={`createSession${key}available`}>{e.prenom} {e.nom.charAt(0)}.</p>
                                                <span   className='material-symbols-outlined'
                                                        onClick={() => {setListeEtudiants([...listEtudiants, e])}}>add_circle</span>
                                            </div>
                                        );
                                    })
                                : <></>}
                            </div>
                        </div>

                        <div className='btn-ctn users'>
                            {listEtudiants.length === 0 ?
                                <p>La liste des étudiants est vide.</p>
                            :<></>}
                            {listEtudiants.map((e) => {
                                return (
                                    <div className='added-student'>
                                        <p>{e.prenom} {e.nom.charAt(0)}.</p>    
                                        <span   className='material-symbols-outlined'
                                                onClick={() => {setListeEtudiants(listEtudiants.filter(student => student.mail !== e.mail))}}>cancel</span>
                                    </div>
                                    )
                            })}
                        </div>
                        <Button name={listEtudiants.length === 0 ? "J'inscrirai les étudiants plus tard." : "J'ai inscrit tout le monde !"}
                                customClassName='next'
                                onClick={() => {setStep("home")}}/>

                        <Button icon={"close"}
                                name={"Retourner en arrière"}
                                onClick={() => {setStep("home")}}
                                customClassName='back'
                                iconPosition='right'
                                styleType='chip'
                                color='_main_full_white'/>
                    </form>
            </div>
        );
    }
    else {
        return ( loading ? 
            <div className="createSession">
                <h3>Lancement de la demande en cours ...</h3>
            </div> :
            <div className="createSession">
                    <form   onSubmit={(e) => {e.preventDefault();launchCreation();}}>
                        {alert !== "" ?
                            <div className="alert">
                                
                                <span   className="material-symbols-outlined icon" >warning</span>
                                <span   className='text'>{alert}</span>
                        </div>:<></>}
                        
                        <Input  type='text'
                                name="name"
                                label_color="_text_green_two"
                                input_color="_main_full_white"
                                placeholder="Titre de la session"
                                value={libelle}
                                onUpdate={setLibelle} />

                        {libelle.length !== 0?
                        <div className="app-form-ctn">
                            <p>Choisir l'application</p>
                            <Button name={selectedEnvironnement === -1 ? "Choisir l'application" : allApplications.filter(app => app.id_environnement === selectedEnvironnement)[0].libelle}
                                    onClick={() => {setStep("application")}}/>
                            
                        </div>:<></>}

                        {libelle.length !== 0 && selectedEnvironnement !== -1 ?
                        <div className="dates-form-ctn">
                            <p>Dates et durée</p>
                            <div className='dates-ctn'>
                                <Button name={dateDebut === undefined ? "Début" : dateDebut.format("DD/MM HH[h]mm")}
                                    onClick={() => {setStep("date-debut");setDateCursor("begin");}}/>
                                    
                                <p>à</p>
                                <Button name={dateFin === undefined ? "Fin" : dateFin.format("DD/MM HH[h]mm")}
                                    onClick={() => {setStep("date-fin");setDateCursor("end");}}/>
                            </div>
                            
                        </div>:<></>}
                        {libelle.length !== 0 && selectedEnvironnement !== -1  && dateDebut !== undefined && dateFin !== undefined ?
                        <div className="users-form-ctn">
                            <p>Liste des étudiants (optionnel)</p>
                            <Button name={listEtudiants.length === 0 ? "Inscrire des étudiants" : `${listEtudiants[0].prenom} ${listEtudiants[0].nom.charAt(0)}. +${listEtudiants.length-1}`} 
                                    onClick={() => {setStep("utilisateurs")}}/>
                        </div>:<></>}

                        <div className='divider'>
                            <p>Résumé</p>
                            <hr/>
                        </div>

                        <Session 
                            state="creation-form"
                            display_submit_button={libelle.length !== 0 && selectedEnvironnement !== -1  && dateDebut !== undefined && dateFin !== undefined}
                            informations={
                            {
                                libelle: libelle.length === 0 ? "Le titre de la session ✨" : libelle,
                                inscriptions: {
                                    inscrits: {
                                        compte: listEtudiants.length
                                    }
                                },
                                environnement: selectedEnvironnement === -1 ? "N/A" : allApplications.filter(app => app.id_environnement === selectedEnvironnement)[0].libelle,

                            }
                            }/>
                    </form>
            </div>
        );
    }
}