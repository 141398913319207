import {BrowserRouter} from 'react-router-dom';
import './App.css';
import RoutingModule from './modules/routing.module';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <RoutingModule />
      </BrowserRouter>
    </div>
  );
}

export default App;
