import { Route, Routes } from "react-router-dom";
import Loader from "../pages/Loader/Loader";
import Dashboard from "../pages/Dashboard/Dashboard";
import Authentification from "../pages/Authentification/Authentification";
import Playground from "../pages/Playground/Playground";
import Applications from "../pages/Applications/Applications";
import Sessions from "../pages/Sessions/Sessions";
import Error from "../pages/Error/Error";

const RoutingModule = () => {
    return (
    <Routes>
        <Route path="/loader" element={<Loader />} />
        <Route path="/auth" element={<Authentification />} />
        <Route path="/apps" element={<Applications />} />
        <Route path="/sess" element={<Sessions />} />
        <Route path="/pg/:id" element={<Playground />} />
        <Route path="/" element={<Dashboard />} />
        <Route path="/401" element={<Error  error="401"/>} />
        <Route path="/400" element={<Error  error="400"/>} />
        <Route path="/402" element={<Error  error="402"/>} />
        <Route path="/403" element={<Error  error="403"/>} />
        <Route path="/*" element={<Error />} />
    </Routes>);
}

export default RoutingModule;