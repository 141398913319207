import { get, post, del, put } from "../modules/ms_api.module";

const lister = async function()  {
    const brut = await get({
        need_token: true,
        uri: "/application/lister"
    });
    const data = await brut.json();
    if (brut.status === 200) return data.result;
    else return [];
}
const creer = async function (libelle = "", id_environnement = -1) {
    if(id_environnement === -1) {
        return {result:false, alert: "Veuillez sélectionner un environnement."}
    }
    const brut = await post({
        need_token: true,
        uri: "/application/",
        body: {
            id_environnement: `${id_environnement}`,
            libelle: `${libelle}`
        }
    })
    const data = await brut.json();
    if (brut.status === 200) return {result: true, datas: data.result};
    else return {result: false, alert: data.result};
}

const informations = async function (key) {
    const brut = await get({
        need_token: true,
        uri: "/application/",
        parameters: [{"key": `${key}`}]
    });
    const data = await brut.json();
    if (brut.status === 200) return data.result;
    else return [];
}

const supprimer = async function (id_application) {
    const brut = await del({
        need_token: true,
        uri: "/application/",
        parameters: [{"id_application": id_application}]
    });
    const data = await brut.json();
    if (brut.status === 200) return data.result;
    else return undefined;
}
const eteindre = async function (id_application) {
    const brut = await put({
        need_token: true,
        uri: "/application/eteindre",
        parameters: [{"id_application": id_application}]
    })
    const data = await brut.json();
    if (brut.status === 200) return "L'application a été éteinte." === data.result;
    else return false;
}
const allumer = async function (id_application) {
    const brut = await put({
        need_token: true,
        uri: "/application/allumer",
        parameters: [{"id_application": id_application}]
    })
    const data = await brut.json();
    if (brut.status === 200) return "L'application a été allumée." === data.result;
    else return false;
}
const applications = {lister, creer, informations, supprimer, allumer, eteindre};
export default applications;