import { useEffect, useState } from 'react';
import Navbar from '../../components/sides/Navbar/Navbar';
import './Dashboard.css';
import applications from '../../services/applications.service';
import MyApplications from '../../components/myApplications/myApplications';
import authentification from '../../services/authentification.service';
import { useNavigate } from 'react-router-dom';
import Calendar from '../../components/elements/Calendar/Calendar';
import moment from 'moment-timezone';
import sessions from '../../services/sessions.service';
import PlannedApplications from '../../components/plannedApplications/plannedApplications';
import Statistics from '../../components/elements/Statistics/Statistics';

export default function Dashboard() {
    const [apps, setApplications] = useState([]);
    const [infos, setInfos] = useState(undefined);
    const [calendarWeeks, setCalendarWeeks] = useState(3);
    const [loading, setLoading] = useState(false);
    const navigator = useNavigate();

    useEffect(() => {
        setLoading(true);
        authentification.informations()
        .then((r) => {
            setLoading(false);
            if (r === "L'utilisateur n'est pas connecté.") navigator("/auth");
            setInfos(r);
        })
        .catch((err) => {
            setLoading(false);
            navigator("/auth");
        });
        refresh();
    }, []);

    const refresh = () => {
        setLoading(true);
        applications.lister().then((r) => {
            setApplications(r);
            setLoading(false);
        });
    }



    return (
        <div className="Dashboard">
            <Navbar user={infos}/>

            {infos !== undefined ? 
                infos.libelle === "ETUDIANT" || infos.libelle == "PROFESSEUR" ?
                <div    className="global-ctn">
                    <div className='left'>
                        <PlannedApplications    apps={apps.filter(a => a.id_session != null && a.libelle !== "Programmée")}/>
                        <MyApplications applications={apps.filter(a => a.id_session == null)}
                                        refresh={refresh} />
                    </div>
                    <Calendar   number_of_weeks={calendarWeeks}
                                beginDate={moment.tz("Europe/Paris")}
                                datas={apps.filter(a => a.id_session != null)}/>
                </div>:<></>
            :<></>}

            {infos !== undefined ? 
                infos.libelle === "ADMINISTRATEUR" ?
                <div    className="global-ctn">
                    <div className='left'>
                        <Statistics />
                    </div>
                    <Calendar   number_of_weeks={calendarWeeks}
                                beginDate={moment()}
                                datas={[]}/>
                </div>:<></>    
            :<></>}
            
            {/*<h1>Prochainement</h1>*/}
        </div>
    );
}