import { useEffect, useState } from 'react';
import Input from '../elements/Input/Input';
import SubmitInput from '../elements/SubmitInput/SubmitInput';
import './createApplication.css';
import environment from '../../services/environment.service';
import Button from '../elements/Button/Button';
import applications from '../../services/applications.service';
import SearchBox from '../elements/SearchBox/SearchBox';

export default function CreateApplication({onCreationComplete = () => {}}) {
    const [categories, setCategories] = useState([]);
    const [selectedCategorie, setSelectedCategorie] = useState(-1);
    const [selectedEnvironnement, setSelectedEnvironnement] = useState(-1);
    const [searchValue, setSearchValue] = useState("");
    const [libelle, setLibelle] = useState("");
    const [allApplications, setAllApplications] = useState([]);
    const [loading, setLoading] = useState(true);
    const [alert, setAlert] = useState("");

    useEffect(() => {
        setLoading(true);
        environment.lister()
        .then((r) => {
            setCategories(r);
            setLoading(false);
        })
        .catch((err) => {
            console.log(err);
            setLoading(false);
        })
    }, []);

    useEffect(() => {
        const tmp = [];
        for (let i = 0; i < categories.length; i++) {
            for (let j = 0; j < categories[i].environnements.length; j++) {
                if (tmp.filter((apps) => apps.libelle === categories[i].environnements[j].libelle).length === 0)
                    tmp.push(categories[i].environnements[j])
            }
        }
        setAllApplications(tmp);
    }, [categories]);

    const launchCreation = () => {
        setLoading(true);
        applications.creer( libelle, selectedEnvironnement).then((r) => {
            onCreationComplete()
            setLoading(false);
        })
    }

    return ( loading ? 
        <div className="CreateApplication">
            <h3>Lancement de la demande en cours ...</h3>
        </div> :
        <div className="CreateApplication">
                <form   onSubmit={(e) => {e.preventDefault();launchCreation();}}>
                    {alert !== "" ?
                        <div className="alert">
                            
                            <span   className="material-symbols-outlined icon" >warning</span>
                            <span   className='text'>{alert}</span>
                    </div>:<></>}
                    <SearchBox  placeholder="Rechercher une application ou une catégorie"
                                searchValue={searchValue}
                                setSearchValue={setSearchValue} />
                            
                    <div className="categorie-ctn">
                        {categories.filter((c) => c.libelle.toLowerCase().includes(searchValue.toLowerCase())).map((c, key) => {
                            return (
                                <div    className="cat-btn-ctn"
                                        key={`Categorie${key}`} >
                                    <Button styleType='chip'
                                            customClassName="categorie"
                                            color={c.id_categorie === selectedCategorie ? '_main_green_three' : '_main_green_two'}
                                            name={c.libelle}
                                            display={selectedCategorie === -1}
                                            onClick={() => {setSelectedCategorie(selectedCategorie === c.id_categorie ? -1 : c.id_categorie)}}
                                            />
                                    
                                    {selectedCategorie === c.id_categorie ? 
                                        <div className='app-btn-ctn'>
                                            <p><span className='material-symbols-outlined' onClick={() => {setSelectedCategorie(-1)}}>arrow_back</span>Dans la catégorie {c.libelle} :</p>
                                            {
                                            c.environnements.map((e, ekey) => {
                                            return (
                                                <Button styleType='chip'
                                                        customClassName="environnement"
                                                        color={selectedEnvironnement === e.id_environnement ? '_main_green_three' : '_main_full_grey'}
                                                        name={e.libelle}
                                                        display={selectedCategorie === c.id_categorie}
                                                        onClick={() => {setSelectedEnvironnement(selectedEnvironnement === e.id_environnement ? -1 : e.id_environnement)}}
                                                        key={`Categorie${key}-${ekey}`} />
                                            );
                                        })}</div>
                                        : <></>}
                                    
                                    
                                </div>
                                
                            );
                        })}
                        {selectedCategorie === -1 && searchValue !== "" ? 
                        allApplications.filter((apps) => apps.libelle.toLowerCase().includes(searchValue.toLowerCase())).map((apps, key) => {
                            return (
                                <Button styleType='chip'
                                        customClassName="environnement"
                                        color={selectedEnvironnement === apps.id_environnement ? '_main_green_three' : '_main_full_grey'}
                                        name={apps.libelle}
                                        onClick={() => {setSelectedEnvironnement(selectedEnvironnement === apps.id_environnement ? -1 : apps.id_environnement)}}
                                        key={`Apps${key}`} />
                            );
                        })
                        :<></>}
                    </div>
                    {selectedEnvironnement !== -1 ?
                    <Input  type='text'
                            name="name"
                            label_color="_text_green_two"
                            input_color="_main_full_white"
                            placeholder="Nom de l'application (optionnel)"
                            value={libelle}
                            onUpdate={setLibelle} />
                    :<></>}
                    {selectedEnvironnement !== -1 ?
                    <SubmitInput    value="Démarrer l'application"
                                    name="submit"
                                    color={"_main_green_three"}/>
                    : <></>}
                </form>
        </div>
    );
}