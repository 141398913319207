import { useState } from 'react';
import Application from '../elements/Application/Application';
import Button from '../elements/Button/Button';
import './myApplications.css';
import CreateApplication from '../createApplication/createApplication';

export default function MyApplications({applications = [], refresh = () => {}}) {
    const [creationForm, setCreationForm] = useState(false);
    
    return (
        <div className="MyApplications">
            <div className='title-ctn'>
                <h1>Mes applications</h1>
                <div className='btn-ctn'>
                    <Button name={creationForm ? "Fermer" : "Démarrer"}
                            icon={creationForm ? "cancel" : "loupe"}
                            iconPosition={"right"}
                            styleType='chip'
                            color={creationForm ? "_main_full_grey" : '_main_full_white'}
                            onClick={() => {setCreationForm(!creationForm)}} />
                </div>
                
            </div>
            
            <div className="apps-ctn">
                {!creationForm && applications.length === 0 ? 
                    <h3>Aucune application n'est lancée pour le moment. 🍃</h3>
                    :<></>
                }
                {creationForm ? <></> : 
                    applications.map((app, key) => {
                        return (
                            <Application    key={`myApplication${key}`}
                                            informations={app}
                                            type={applications.length >= 5 ? 'liste' : ''}
                                            state={app.libelle === "Éteinte" ? "standby" : app.libelle ==="Bientôt prête" ? "launching" : "running"}
                                            onDeletion={refresh} />);
                    })}
            </div>

            {!creationForm ? <></> : 
                <div className="form-ctn">
                    <CreateApplication  onCreationComplete={() => {setCreationForm(!creationForm); refresh();}} />
                </div>
            }
        </div>
    );
}