import { get } from "../modules/ms_api.module";

const lister = async function () {
    const brut = await get({
        need_token: true,
        uri: "/environnement/categories/lister"
    });
    const data = await brut.json();
    if (brut.status === 200) return data.result;
    else return [];
}

const environment = {lister};
export default environment;