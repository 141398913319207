import './Statistics.css';

export default function Statistics() {
    return (
        <div    className={`Statistics`}>
            <div className='title-ctn'>
                <h1>Comment va Cirrus ?</h1>
            </div>
            <div className="stats-ctn">
                <div className='stat first shadow'>
                    <span className='value MontSerrat-Black'>0</span>
                    <span className='libelle'>Applications allumées</span>
                </div>
                <div className='stat second shadow' >
                    <span className='value MontSerrat-Black'>0</span>
                    <span className='libelle'>Etudiants courants</span>
                </div>
                <div className='stat third shadow'>
                    <span className='value MontSerrat-Black'>0</span>
                    <span className='libelle'>€ estimés pour déc. 2023</span>
                </div>
            
                <div className='fourth stat shadow'>
                    <div className='stat first'>
                        <span className='value MontSerrat-Black'>0</span>
                        <span className='libelle'>Sessions en cours</span>
                    </div>
                    <div className='stat second'>
                        <span className='value MontSerrat-Black'>0</span>
                        <span className='libelle'>Sessions à venir</span>
                    </div>
                    <div className='stat third'>
                        <span className='value MontSerrat-Black'>0</span>
                        <span className='libelle'>Sessions terminées</span>
                    </div>
                </div>
                
            
                <div className='stat fifth shadow'>
                    <span className='value MontSerrat-Black'>0</span>
                    <span className='libelle'>minutes sauvées sur déc. 2023</span>
                </div>
                <div className='stat sixth shadow'>
                    <span className='value MontSerrat-Black'>0</span>
                    <span className='libelle'>minutes sauvées par étudiant</span>
                </div>
                
            </div>
            <div className='title-ctn-suite'>
                <h1>Plus technique</h1>
            </div>
        </div>
    );
}