import { useState, useRef, useEffect } from 'react';
import Button from '../Button/Button';
import './Application.css';
import BlurredBackground from '../BlurredBackground/BlurredBackground';
import applications from '../../../services/applications.service';

export default function Application({informations = undefined, state = "running", onDeletion = () => {}, type = "application"}) {
    const [openSubContainer, setOpenSubContainer] = useState(false);
    const [isPressing, setPressing] = useState(false);
    const [selected, setSelected] = useState(false);
    const [confirmDeletion, setConfirmDeletion] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const timeoutRef = useRef(null);
    useEffect(() => {
        setTimeout(() => {
            setShowPassword(false);
        }, 5000);
    }, [showPassword]);

    const handleMouseDown = () => {
        setPressing(true);
        timeoutRef.current = setTimeout(() => {
            setSelected(!selected);
            setOpenSubContainer(false)
          }, 1000);
    }
    const handleMouseUp = () => {
        setPressing(false);
        clearTimeout(timeoutRef.current);
    }

    const deletion = () => {
        applications.supprimer(informations.id_application).then((r) => {
            onDeletion()
            setSelected(!selected);
            setShowPassword(false);
            setOpenSubContainer(false)
        })
    }

    const eteindre = () => {
        applications.eteindre(informations.id_application)
        .then((r) => {
            onDeletion();
            setSelected(!selected);
            setShowPassword(false);
            setOpenSubContainer(false)
        });
    }

    const allumer = () => {
        applications.allumer(informations.id_application)
        .then((r) => {
            onDeletion();
            setSelected(!selected);
            setShowPassword(false);
            setOpenSubContainer(false)
        });
    }
    return (
    <>
        <BlurredBackground  display={selected} onClick={() => {setShowPassword(false);setSelected(false); setOpenSubContainer(false);}}/>

        <div    className={`Application ${selected ? "selected" : ""} ${state} `}
                onMouseDown={handleMouseDown}
                onMouseUp={handleMouseUp}
                onMouseLeave={handleMouseUp}
                onTouchStart={handleMouseDown}
                onTouchEnd={handleMouseUp}
                onDoubleClick={() => {setSelected(!selected);setShowPassword(false);setOpenSubContainer(false)}}>
            <div    className={`UpSubContainer ${selected ? "selected" : ""}  `}>
                {informations.libelle === "Bientôt prête" ?
                <Button icon={"progress_activity"}
                        name="Démarrage en cours ..."
                        styleType={"chip"}
                        customClassName='_main_grey_to_white'
                        
                />:
                <Button icon={informations.libelle === "Éteinte" ? "bolt" : "eco"}
                        name={informations.libelle === "Éteinte" ? "Démarrer" : "Éteindre"}
                        styleType={"chip"}
                        customClassName={informations.libelle === "Éteinte" ? '_main_green_three' : '_main_red'}
                        isLink={false}
                        onClick={() => (informations.libelle === "Éteinte" ? allumer() : eteindre())}/>}
            </div>
            
            {/* Container global */}
            
            <div className='container'>
                <div className='firstRow'>
                    <h1>{informations.nom === "" ? informations.unique_name : informations.nom}</h1>
                    <div className='status'>
                        <p>{informations.libelle}</p>
                        <span   className="material-symbols-outlined" >{informations.libelle === "Éteinte" ? "bedtime":"verified"}</span>
                    </div>  
                </div>
                {type !== "liste" || (type === "liste" && selected) ?
                <ul className="informations-gbl-ctn">
                    <li>
                        <span className='material-symbols-outlined'>branding_watermark</span>
                        {`${informations.interfaces.length} interfaces`}
                    </li>
                    <li>
                        <span className='material-symbols-outlined'>source_environment</span>
                        {`${informations.libelle_environnement}`}
                    </li>
                </ul>:<></>}

                {type !== "liste" || (type === "liste" && selected) ?
                <ul className="actions-gbl-ctn">
                    <li><Button icon={openSubContainer ? "close" : "widgets"}
                                name={openSubContainer ? "Fermer" : "Paramètres"} 
                                onClick={() => {setShowPassword(false);setSelected(!openSubContainer); setOpenSubContainer(!openSubContainer); setConfirmDeletion(false)}}
                                styleType={"chip"}/>
                    </li>
                    {informations.libelle !== "Bientôt prête" && informations.libelle !== "Programmée" ?
                    <li>
                        <Button icon={informations.libelle === "Éteinte" ? "bolt" : "launch"}
                                name={informations.libelle === "Éteinte" ? "Démarrer" : "Ouvrir"}
                                styleType={"chip"}
                                customClassName={informations.libelle === "Éteinte" ? "_main_green_three": ""}
                                isLink={informations.libelle !== "Éteinte"}
                                linkPath={'/pg/'+informations.unique_name}
                                onClick={() => {allumer()}} />
                    </li>:<></>}
                </ul>: <></>}

                {/* Sub-container for more parameters */}
                {!openSubContainer ? <></> :
                <div className='informations-ctn'>
                    <ul className="actions-informations-ctn">
                        {/*<li><Button icon={"cloud_download"}
                                    name={"Télécharger les données"} 
                                    color={"_main_grey"}
                                    styleType={"chip"}/>
                         </li>*/}
                        <li><Button icon={"shield_person"}
                                    name={!showPassword ? "Compte administrateur" : `${informations.username} / ${informations.password}`}
                                    color={"_main_grey"}
                                    onClickTimer={5000}
                                    onClick={() => setShowPassword(true)}
                                    styleType={"chip"}
                                     />
                        </li>
                        {/*<li><Button icon={"power_settings_new"}
                                    name={"Eteindre"}
                                    color={"_main_red"} 
                                    styleType={"chip"}/>
                        </li>*/}
                        {informations.libelle !== "Programmée" ?
                        confirmDeletion ?
                        <li>
                            <Button icon={"delete_forever"}
                                    name={"Sûr et certain ?"}
                                    color={"_main_red_confirm"}
                                    onClick={() => {deletion()}}
                                    styleType={"chip"} />
                        </li>:
                        <li>
                            <Button icon={"delete_forever"}
                                    name={"Supprimer"}
                                    color={"_main_red"}
                                    onClick={() => {setConfirmDeletion(true)}}
                                    styleType={"chip"} />
                        </li> : <></>}
                    </ul>
                </div>}
            </div>
            
        </div>
        
    </>
    );
}