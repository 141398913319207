import { Link, useNavigate, useParams } from 'react-router-dom';
import './Playground.css';
import Navbar from '../../components/sides/Navbar/Navbar';
import { useEffect, useRef, useState } from 'react';
import authentification from '../../services/authentification.service';
import applications from '../../services/applications.service';
import BlurredBackground from '../../components/elements/BlurredBackground/BlurredBackground';
import Iframe from '../../components/elements/Iframe/Iframe';

export default function Playground() {
    const [loading, setLoading] = useState(true);
    const {id} = useParams();
    const [selectedInterface, setSelectedInterface] = useState(0);
    const [infos, setInfos] = useState(undefined);
    const [displayInterfaceSelection, setDisplayInterfaceSelection] = useState(false);
    const [interfaces, setInterfaces] = useState(undefined);
    const [displayType, setDisplayType] = useState("full-window");
    const navigator = useNavigate();
    
    useEffect(() => {
        setLoading(true);
        authentification.informations()
        .then((r) => {
            setLoading(false);
            if (r === "L'utilisateur n'est pas connecté.") navigator("/auth");
            setInfos(r);
        })
        .catch((err) => {
            setLoading(false);
            navigator("/auth");
        });
        applications.informations(id).then((r) => {
            setInterfaces(r);
            document.title = r.nom === "" ? r.unique_name : r.nom;
        })
        
    }, []);

    useEffect(() => {
        window.addEventListener('keydown', handleKeyPress);
    }, [interfaces, selectedInterface]);

    const handleKeyPress = (event) => {
        if (interfaces !== undefined) {
            if (event.ctrlKey && event.key === 'ArrowRight') {
                if (selectedInterface < (interfaces.interfaces.length-1)) setSelectedInterface(selectedInterface+1);
            } else if (event.ctrlKey && event.key === 'ArrowLeft') {
                if (selectedInterface > 0) setSelectedInterface(selectedInterface-1);
            }
        }
        
      };

    return (
        <div className="Playground">
            <BlurredBackground  display={displayInterfaceSelection}
                                onClick={() => {setDisplayInterfaceSelection(!displayInterfaceSelection)}} />
            {interfaces !== undefined && displayInterfaceSelection ? 
                <div    className='modal-ctn'
                onClick={() => {setDisplayInterfaceSelection(!displayInterfaceSelection)}}>
                    <div className='selection-ctn'>
                        {interfaces.interfaces.map((i, key) => {
                            return (
                                <h3 onClick={() => {setSelectedInterface(key)}}>{key === selectedInterface ? `- ${i.libelle} (${i.service}) -` : `${i.libelle} (${i.service})`}</h3>
                            );
                        })}    
                    </div>
                </div>
            :<></>}

            {interfaces !== undefined ? 

                <div className={`fullWindow`}>
                    
                    <div className='title-ctn'>
                        <div className='home-ctn'>
                            <Link to="/" target="_blank" rel="noopener noreferrer">
                                <img src='../no_bg_logo_uni.svg' />
                                <h2>Cirrus</h2>  
                            </Link>
                        </div>
                        {displayType === "full-window" ?
                            <div className='window-ctn'>
                                <span   className={`material-symbols-outlined back ${selectedInterface === 0 ? "not" : ""}`}
                                        onClick={() => {if(selectedInterface!==0)setSelectedInterface(selectedInterface-1)}}>arrow_back</span>  
                                <span   className='title'
                                        onClick={() => {setDisplayInterfaceSelection(!displayInterfaceSelection)}}>{interfaces.interfaces[selectedInterface].libelle} ({interfaces.interfaces[selectedInterface].service})</span>
                                <span   className={`material-symbols-outlined forward ${selectedInterface === (interfaces.interfaces.length -1) ? "not" : ""}`}
                                        onClick={() => {if(selectedInterface!==(interfaces.interfaces.length -1)) setSelectedInterface(selectedInterface+1)}}>arrow_forward</span>
                            </div>
                        :<></>}
                        
                        {/*<span   class="material-symbols-outlined split-bottom"
                                onClick={() => {setDisplayType(displayType === "horizontal-split" ? "full-window" : "horizontal-split")}}>{displayType === "horizontal-split" ? "iframe" : "splitscreen_bottom"}</span>
                        <span   class="material-symbols-outlined split"
            onClick={() => {setDisplayType(displayType === "vertical-split" ? "full-window" : "vertical-split")}}>{displayType === "vertical-split" ? "iframe" : "splitscreen_right"}</span>*/}
                        <span class="material-symbols-outlined settings"><Link to="/settings" target="_blank" rel="noopener noreferrer">settings</Link></span>
                    </div>
                </div>
            :<></>}
            {interfaces !== undefined ?
                interfaces.interfaces.map((int, key) => {
                    return (
                    <div    className={`${displayType} ${key === selectedInterface  ? "displayed" : "not-displayed"} iframe-ctn` }>
                        {displayType !== "full-window"?
                        <div className='title-ctn'>
                            <div className='window-ctn'>
                                <span   className={`material-symbols-outlined back ${(selectedInterface === key && selectedInterface === 0) ? "not" : ""}`}
                                        onClick={() => {
                                            if(selectedInterface === key 
                                                && selectedInterface!==0)
                                                setSelectedInterface(selectedInterface-1)
                                        }}>arrow_back</span>  
                                <span   className='title'>{int.libelle} ({int.service})</span>
                                <span   className={`material-symbols-outlined forward ${(selectedInterface === key  && selectedInterface === (interfaces.interfaces.length -1))  ? "not" : ""}`}
                                        onClick={() => {
                                            if(selectedInterface === key 
                                                && selectedInterface!==(interfaces.interfaces.length -1)) 
                                                setSelectedInterface(selectedInterface+1)
                                            
                                        }}>arrow_forward</span>
                            </div>
                        </div>
                        :<></>}
                        <Iframe link={int.link}/>
                    </div>
                    );
                    
                })
            :<></>}

        </div>
    );
}