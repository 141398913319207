import moment from 'moment-timezone';
import Calendar from '../../components/elements/Calendar/Calendar';
import Navbar from '../../components/sides/Navbar/Navbar';
import authentification from '../../services/authentification.service';
import './Sessions.css';
import { useEffect, useState } from 'react';
import {  useNavigate } from 'react-router-dom';
import CurrentSessions from '../../components/currentSessions/currentSessions';
import sessions from '../../services/sessions.service';

export default function Sessions() {
    const [sess, setSessions] = useState([]);
    const [infos, setInfos] = useState(undefined);
    const [calendarWeeks, setCalendarWeeks] = useState(3);
    const [loading, setLoading] = useState(false);
    const navigator = useNavigate();

    useEffect(() => {
        setLoading(true);
        authentification.informations()
        .then((r) => {
            setLoading(false);
            if (r === "L'utilisateur n'est pas connecté.") navigator("/auth");
            setInfos(r);
        })
        .catch((err) => {
            setLoading(false);
            navigator("/auth");
        });
        refresh();
    }, []); 

    const refresh = () => {
        sessions.lister().then((r) => {
            setSessions(r);
        })
    }

    return (
        <div className="Dashboard">
            <Navbar user={infos}/>

            <div    className="global-ctn">
                <div className='left'>
                    <CurrentSessions    sessions={sess} 
                                        refresh={refresh}/>
                </div>
                <Calendar   number_of_weeks={calendarWeeks}
                            beginDate={moment.tz("Europe/Paris")}
                            datas={sess.filter(s => s.etat === "Démarre bientôt")}/>
            </div>
        </div>
    );
}