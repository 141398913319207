
import { saveToken, removeToken, getToken } from "../modules/localStorage.module";
import { get, post } from "../modules/ms_api.module";
const {REACT_APP_API_ADRESS} = process.env;

/**
 * Méthode permettant de contacter la route /utilisateur/informations de ms-api.
 * @returns 
 */
const informations =async function() {
    const brut = await get({
        need_token: true,
        uri: "/utilisateur/informations"
    });
    if (brut === "L'utilisateur n'est pas connecté.") return brut;
    const data = await brut.json();
    if (brut.status === 200) {
        return data.result;
    }
    else if (brut.status === 401) {
        deconnexion();
        return "L'utilisateur n'est pas connecté.";
    }
    else {
        return "Un problème a eu lieu durant le traitement de cette requête. Veuillez recharger la page."
    }
}

/**
 * Méthode permettant de contacter la route /authentification/connexion de ms-api.
 * @param {*} param0 
 * @returns 
 */
const connexion_credentials = async function({mail, pwd}) {
    if(mail === undefined || mail === "") { return {result:false, alert: "Veuillez insérer votre adresse mail."}} 
    else if (pwd === undefined || pwd === ""){ return {result:false, alert: "Veuillez insérer votre mot de passe."}}

    const brut = await post({
        need_token: false,
        uri: "/authentification/connexion",
        body: { 
            login: `${mail}`,
            mdp: `${pwd}`
        }
    });
    const data = await brut.json();
    // Sauvegarde du token
    if (brut.status === 200) {
        saveToken(data.result);
        return {result:true};
    }
    else return {result:false, alert: "Les informations d'identification fournies ne sont pas correctes."};
}

/**
 * Méthode permettant de déconnecter et de supprimer l'utilisateur courant.
 */
export function deconnexion() {
    removeToken();
}


const est_connecte = async function() {
    const brut = await get({
        need_token: true,
        uri: "/authentification"
    })
    const data = await brut.json();
    if(!data.result) removeToken();
    return data.result;
}

const connect_by_oidc = async function() {
    const options = {
        method: 'GET',
    }
    const brut = await fetch(`${REACT_APP_API_ADRESS}/authentification/oidc`, options);
    const data = await brut.json();
    return data.result;
}
const auth_types = async function () {
    const options = {
        method: 'GET'
    }

    const brut = await fetch(`${REACT_APP_API_ADRESS}/authentification/available`, options);
    const data = await brut.json();
    return data.result;
}
const authentification = {auth_types,informations, deconnexion, connexion_credentials, connect_by_oidc, est_connecte};
export default authentification;