import { useNavigate } from 'react-router-dom';
import './Loader.css';
import { useEffect } from 'react';
import authentification from '../../services/authentification.service';

export default function Loader() {
    const navigate = useNavigate();

    useEffect(() => {
        authentification.est_connecte().then((res) => {
            if(!res) navigate("/auth");
            else navigate("/");
        });
    }, []);

    return (
        <div className="Loader">
            <img src="./no_bg_logo.svg" alt="Logo de l'application Cirrus en blanc sans fond."/>
            <h1 className='caelus-app-name'>Cirrus</h1>

            <div className='footer'>
                <img src='./caelus-without-background.svg' alt="Logo de l'entreprise Caelus en blanc sans fond."/>
                <div className="caelus-app-name">Caelus</div>
            </div>
        </div>
    );
}