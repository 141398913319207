import { useState } from 'react';
import Session from '../elements/Session/Session';
import './currentSessions.css';
import Button from '../elements/Button/Button';
import CreateSession from '../createSession/createSession';

export default function CurrentSessions({sessions = [], refresh = null}) {
    const [creationForm, setCreationForm] = useState(false);
    const [displayClosed, setDisplayClosed] = useState(false);

    return (
        <div className="CurrentSessions">
            <div className='title-ctn'>
                <h1>Sessions {displayClosed ? "terminées" : "en cours"}</h1>
                <div className='btn-ctn'>
                    <Button name={creationForm ? "Fermer" : "Programmer"}
                            icon={creationForm ? "cancel" : "event_note"}
                            iconPosition={"right"}
                            styleType='chip'
                            color={creationForm ? "_main_full_grey" : '_main_full_white'}
                            onClick={() => {setCreationForm(!creationForm)}} />
                        
                </div>
            </div>
            <div className="apps-ctn">
                {!creationForm ? 
                sessions.filter(s => ( !displayClosed && s.etat === "En cours") || ( displayClosed && s.etat === "Terminée"))
                .map((app, key) => {
                    return (
                        <Session    key={`CurrentSession${key}`}
                                    informations={app}
                                    refresh={refresh}
                                    state={app.etat === "Terminée" ? "standby" : app.etat === "Bientôt prête" ? 'planned' : 'session'} />);
                }):<></>}
            </div>
            {creationForm && sessions.filter(s => s.etat === "Terminée")? <></> :
            <Button icon={"east"}
                    name={displayClosed ? "Voir les sessions en cours" : "Voir les sessions terminées"}
                    iconPosition='right'
                    styleType='chip'
                    color='_main_full_white'
                    onClick={() => {setDisplayClosed(!displayClosed)}}/>}
            
            {!creationForm ? <></> : 
                <div className="form-ctn">
                    <CreateSession  onCreationComplete={() => {setCreationForm(!creationForm); refresh()}} />
                </div>
            }
        </div>
    );
}