import Input from '../Input/Input';
import './SearchBox.css';

export default function SearchBox({ searchValue = "", setSearchValue = () => true, placeholder = ""}) {
    return (
        <div    className="SearchBox">
            <Input  type='text'
                    display_label={false}
                    name="search"
                    label_color="_text_green_two"
                    input_color="_main_full_white"
                    placeholder={placeholder}
                    value={searchValue}
                    onUpdate={setSearchValue} />
        </div>
    );
}