import { useEffect, useState } from 'react';
import './Authentification.css';
import Input from '../../components/elements/Input/Input';
import SubmitInput from '../../components/elements/SubmitInput/SubmitInput';
import Divider from '../../components/elements/Divider/Divider';
import Button from '../../components/elements/Button/Button';
import authentification from '../../services/authentification.service';
import { saveToken } from '../../modules/localStorage.module';
import { useNavigate } from 'react-router-dom';
const {REACT_APP_API_ADRESS} = process.env;

export default function Authentification() {
    const [loading, setLoading] = useState(false);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [authTypes, setAuthTypes] = useState({
        oidc: true,
        credentials: true
    });
    const [alert, setAlert] = useState("");

    const navigate = useNavigate();

    useEffect(() => {
        setLoading(true);
        authentification.auth_types().then((r) => {
            setAuthTypes({
                oidc: r.oidc === "True" || r.oidc === "true",
                credentials: r.credentials === "True" || r.credentials === "true"
            });
            setLoading(false);
        }); 
    }, []);

    const oidc_connexion = () => {
        setLoading(true);
        const popup = window.open(REACT_APP_API_ADRESS+"/authentification/oidc", 'OIDC Auth', 'width=500,height=600');
        // Listen for messages from the popup window
        window.addEventListener('message', (event) => {
        if (event.source === popup) {
          saveToken(event.data.result)
          setLoading(false);
          navigate("/loader")
          // Close the popup (optional)
          popup.close();
        }
      });

    }

    const credentials_connexion = () => {
        setLoading(true);
        authentification.connexion_credentials({ mail: username, pwd: password })
        .then((res) => {
            if(res.result) navigate("/loader");
            else setAlert(res.alert);
            setUsername("");
            setPassword("");
            setLoading(false);
        }); 
    }

    return (
        <div className="Authentification">
            <div className="auth-ctn">
                <div className='logo-ctn'>
                    <img src="./no_bg_logo_uni.svg" />
                    <h1>Cirrus</h1>
                </div>
                
                {!loading && authTypes.credentials ?
                    <form onSubmit={credentials_connexion}>
                        <h2>Connexion</h2>
                        <Input  type="text" 
                                value={username}
                                placeholder="Nom d'utilisateur / E-mail"
                                name="username"
                                onUpdate={setUsername}/>
                        <Input  type="password" 
                                value={password}
                                placeholder="Mot de passe"
                                name="password"
                                onUpdate={setPassword}/>
                        {alert !== "" ?
                        <div className="alert">
                            
                            <span   className="material-symbols-outlined icon" >warning</span>
                            <span   className='text'>{alert}</span>
                        </div>:<></>}
                        <SubmitInput    value='Me connecter'/>
                        {authTypes.oidc ? <Divider /> : <></>}
                        {authTypes.oidc ?
                            <Button name="Se connecter avec Caelus"
                                    icon="caelus"
                                    color={"_main_green_two"}
                                    onClick={() => {oidc_connexion();}}/>
                            :<></>}
                    </form>
                :<></>}
            </div>
            <div className='footer'>
                <img src='./caelus-without-background.svg' alt="Logo de l'entreprise Caelus en blanc sans fond."/>
                <div className="caelus-app-name">Caelus</div>
            </div>
        </div>
    );
}