import { useState, useRef } from 'react';
import './Session.css';
import BlurredBackground from '../BlurredBackground/BlurredBackground';
import SubmitInput from '../SubmitInput/SubmitInput';
import Button from '../Button/Button';
import sessions from '../../../services/sessions.service';

export default function Session({informations = undefined, state = "running", display_submit_button = false, refresh = () => {}}) {
    const [isPressing, setPressing] = useState(false);
    const [selected, setSelected] = useState(false);
    const timeoutRef = useRef(null);

    const handleMouseDown = () => {
        setPressing(true);
        timeoutRef.current = setTimeout(() => {
            setSelected(!selected);
          }, 1000);
    }
    const handleMouseUp = () => {
        setPressing(false);
        clearTimeout(timeoutRef.current);
    }

    const closeSession = () => {
        console.log(informations.id_session)
        sessions.close(informations.id_session).then((r) => {
            refresh()
        })
    }

    return (
    <>
        <BlurredBackground  display={selected && state !== "creation-form"} onClick={() => {setSelected(false);}}/>

        <div    className={`Session ${selected ? "selected" : ""} ${state} `}
                onMouseDown={handleMouseDown}
                onMouseUp={handleMouseUp}
                onMouseLeave={handleMouseUp}
                onTouchStart={handleMouseDown}
                onTouchEnd={handleMouseUp}
                onDoubleClick={() => {setSelected(!selected);}}>
            {/* Container global */}
            
            <div className='container'>
                <div className='firstRow'>
                    <h1>{informations.libelle === "" ? informations.generated_name : informations.libelle}</h1>
                    <div className='status'>
                        <p>{informations.etat}</p>
                        <span   className="material-symbols-outlined" >{informations.etat === "Terminée" ? "bedtime":"verified"}</span>
                    </div>  
                </div>

                {/* Sub-container for more parameters */}
                {!selected && state !== "creation-form"  ? <></> :
                    <div className='informations-ctn'>
                        <ul>
                            {informations.inscriptions.participants === undefined ?
                                <li><span className='material-symbols-outlined'>check</span><p>{informations.inscriptions.inscrits.compte} personnes.</p></li> :
                                <li><span className='material-symbols-outlined'>check</span><p>{informations.inscriptions.participants.compte} / {informations.inscriptions.inscrits.compte}</p></li> }
                            <li><span className='material-symbols-outlined'>source_environment</span><p>{informations.environnement}</p></li>
                            {informations.generated_name !== undefined ? <li><span className='material-symbols-outlined'>key</span><p>{informations.generated_name}</p></li> : <></>}
                        </ul>
                    </div>}

                {state === "creation-form" && display_submit_button ? 
                    <SubmitInput    value="Créer la session"
                                    name="caelus"
                                    font='MontSerrat-Regular'/>
                : <></>} 
                {selected && state !== "creation-form" && informations.etat !== "Démarre bientôt" && informations.etat !== "Terminée"?
                <Button name='Mettre fin à la session'
                        customClassName='_main_red_confirm'
                        onClick={() => {closeSession()}}
                        />:<></>}
            </div>
            
        </div>
        
    </>
    );
}