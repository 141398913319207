import moment from 'moment-timezone';
import Calendar from '../../components/elements/Calendar/Calendar';
import MyApplications from '../../components/myApplications/myApplications';
import PlannedApplications from '../../components/plannedApplications/plannedApplications';
import Navbar from '../../components/sides/Navbar/Navbar';
import applications from '../../services/applications.service';
import authentification from '../../services/authentification.service';
import './Applications.css';
import { useEffect, useState } from 'react';
import {  useNavigate } from 'react-router-dom';

export default function Applications() {
    const [apps, setApplications] = useState([]);
    const [infos, setInfos] = useState(undefined);
    const [calendarWeeks, setCalendarWeeks] = useState(3);
    const [loading, setLoading] = useState(false);
    const navigator = useNavigate();

    useEffect(() => {
        setLoading(true);
        authentification.informations()
        .then((r) => {
            setLoading(false);
            if (r === "L'utilisateur n'est pas connecté.") navigator("/auth");
            setInfos(r);
        })
        .catch((err) => {
            setLoading(false);
            navigator("/auth");
        });
        refresh();
    }, []);

    const refresh = () => {
        setLoading(true);
        applications.lister().then((r) => {
            setApplications(r);
            setLoading(false); 
        });
    }

    return (
        <div className="Dashboard">
            <Navbar user={infos}/>

            <div    className="global-ctn">
                <div className='left'>
                    <PlannedApplications    apps={apps.filter(a => a.id_session != null && a.libelle !== "Programmée")}/>
                    <MyApplications applications={apps.filter(a => a.id_session == null)}
                                    refresh={refresh} />
                </div>
                <Calendar   number_of_weeks={calendarWeeks}
                            beginDate={moment.tz("Europe/Paris")}
                            datas={apps.filter(a => a.id_session != null)}/>
            </div>
        </div>
    );
}