import './SubmitInput.css';

export default function SubmitInput({value = "Pas de valeur fournie.", color = "_main_green_two", name = "noname", font = "MontSerrat-ExtraBold"}) {
    return (
        <div className={`SubmitInput ${name}`}>
            <input  type="submit"
                    className={`${font} ${color}`}
                    value={value}
                />        
        </div>
    );
}