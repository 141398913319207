import Application from '../elements/Application/Application';
import './plannedApplications.css';

export default function PlannedApplications({apps = []}) {
    
    if (apps.filter(app => app.libelle !== "Éteinte").length === 0) return (<></>);
    return (
        <div className="PlannedApplications">
            <div className='title-ctn'>
                <h1>Selon mon planning</h1>
            </div>
            <div className="apps-ctn">
                {apps.filter(app => app.libelle !== "Éteinte").map((app, key) => {
                    return (
                        <Application    key={`PlannedApplication${key}`}
                                        informations={app}
                                        state={app.libelle === "Bientôt prête" ? 'planned' : 'session'} />);
                })}
            </div>
        </div>
    );
}