import moment from 'moment-timezone';
import Sidebar from '../Sidebar/Sidebar';
import './Navbar.css';
import { useEffect, useState } from 'react';

export default function Navbar({user = undefined, relativePath = "./"}) {
    const [city, setCity] = useState("Lille");
    const [displaySidebar, setDisplaySidebar] = useState(false);
    const [now, setNow] = useState(moment.tz("Europe/Paris"));
    useEffect(() => {
        updateNow();
    }, []);

    const updateNow = () => {
        setInterval(() => {setNow(moment.tz("Europe/Paris"))}, 1000);
    }

    return (
        <>
            <div    className="Navbar">
                <div    className="home-button col-sm-3"
                        >
                    <img    className="icon" src={relativePath+"no_bg_logo.svg"} alt="Logo de l'application Cirrus." />
                    <span   className="icon material-symbols-outlined"
                            onClick={() => {setDisplaySidebar(!displaySidebar)}}>{displaySidebar ? "close" : "menu"}</span>
                    <div className="caelus-app-name">Cirrus</div>
                </div>
                <div className='informations'>
                    <div    className='alldate'>
                        <span>{now.format("dddd DD MMMM [-] ")}</span>
                        <span className='hour MontSerrat-Regular'>{now.format("HH[h]mm")}</span>
                    </div>
                    <span    className='onlyhour'>
                        {now.format("HH[h]mm")}
                    </span>
                    <div   className='onlyweather'>
                        <span className="material-symbols-outlined">partly_cloudy_day</span>
                        <span>{city}</span>
                    </div>
                </div>
                {user !== undefined ? <h3 className="col-sm-5">{`${user.prenom} ${user.nom[0]}.`}</h3> : <></>}
            </div>
                
            <Sidebar    display={displaySidebar}
                        relativePath={relativePath}
                        role={user === undefined ? "ETUDIANT" : user.libelle}
                        onClick={() => {setDisplaySidebar(!displaySidebar)}}/>
        </>
    );
}