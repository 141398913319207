import { Link, useLocation, useNavigate } from 'react-router-dom';
import { deconnexion } from '../../../services/authentification.service';
import BlurredBackground from '../../elements/BlurredBackground/BlurredBackground';
import './Sidebar.css';

export default function Sidebar({   role = "ETUDIANT", 
                                    display = true, 
                                    relativePath = "./",
                                    onClick = () => {}}) {
    const navigator = useNavigate();
    const location = useLocation();


    return (
        <>
            <BlurredBackground  onClick={() =>  {onClick()}}
                                display={display}/>
            <div className={`Sidebar ${display ? '' : 'not'}`}>
                
                <Link   className={`link one ${location.pathname === "/" ? "selected": ""}`}
                        to="/">
                    <span className='material-symbols-outlined'>home</span>
                    <h3>Accueil</h3>
                </Link>
                {["ADMINISTRATEUR", "PROFESSEUR"].includes(role) ?
                <Link   className={`link two ${location.pathname === "/apps" ? "selected": ""}`}
                        to="/apps">
                    <span className='material-symbols-outlined'>apps</span>
                    <h3>Mes applications</h3>
                </Link>
                :<></>}
                {["ADMINISTRATEUR", "PROFESSEUR"].includes(role) ?
                <Link   className={`link three ${location.pathname === "/sess" ? "selected": ""}`}
                        to="/sess">
                    <span className='material-symbols-outlined'>school</span>
                    <h3>Mes sessions</h3>
                </Link>
                :<></>}
                <div className='link eleven'>
                    <span className='material-symbols-outlined'>settings</span>
                    <h3>Paramètres</h3>
                </div>
                <div className='link twelve'>
                    <span className='material-symbols-outlined' onClick={(e) => {e.preventDefault();deconnexion();navigator("/auth");}}>logout</span>
                    <h3 onClick={(e) => {e.preventDefault();deconnexion();navigator("/auth");}}>Déconnexion</h3>
                </div>
                
            </div>
        </>
    );
}