export const saveToken = (token) => {
    setItem("token", {token: token});
}

export const removeToken = () => {
    removeItem("token");
}

function setItem(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
}

function removeItem(key) {
    localStorage.removeItem(key);
}
export const getToken = () => {
    const token = getStorageValue("token");
    return token;
}

function getStorageValue(key){
    const saved = localStorage.getItem(key);
    if (saved === undefined) return undefined;
    const initial = JSON.parse(saved);
    return initial || null;
  }
