import React, { useEffect, useRef } from 'react';
import { getToken } from "../../../modules/localStorage.module";
import './Iframe.css';

const Iframe = ({link = "" }) => {


  return (
    <div className='Iframe'>
      <iframe src={`${link}?token=${getToken().token}`}
              loading='lazy'></iframe>
    </div>
    
  );
};

export default Iframe;