import './Input.css';

export default function Input({ label_color="_text_white", 
                                input_color="_main_white",
                                type = "text", 
                                name = "no-name", 
                                placeholder = "Aucun placeholder fourni.", 
                                value = "", 
                                onUpdate = null,
                                display_label = true}) {
    return (
        <div    className={`Input ${name}`}>
            {display_label ? 
            <label  htmlFor={name}
                    className={label_color}>{placeholder}</label>
            :<></>}
            <input  type={type}
                    className={input_color}
                    placeholder={placeholder}
                    onChange={(e) => {onUpdate(e.target.value);}}
                    value={value}
                />        
        </div>
    );
}