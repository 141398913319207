import { get, post, put } from "../modules/ms_api.module";

const lister = async function() {
    const brut = await get({
        need_token: true,
        uri: "/session/lister"
    });
    const data = await brut.json();
    if (brut.status === 200) return data.result;
    else return [];
}

const creer = async function({libelle, date_crea, date_fin, id_environnement, etudiants = [], timezone="Europe/Paris"}) {
    const brut = await post({
        need_token: true,
        uri: "/session",
        body: {
            libelle: `${libelle}`,
            id_environnement: `${id_environnement}`,
            date_crea: `${date_crea.format("YYYY-MM-DD HH:mm:ss")}`,
            date_fin: `${date_fin.format("YYYY-MM-DD HH:mm:ss")}`,
            etudiants: etudiants.map(obj => obj.id_etudiant).join(',')
        }
    });
    const data = await brut.json();
    if (brut.status === 200) return data.result;
    else return [];
}

const close = async function (id_session) {
    const brut = await put({
        need_token: true,
        uri: "/session/terminer",
        body: {
            id_session: `${id_session}`
        }
    })
    const data = await brut.json();
    if (brut.status === 200) return data.result;
    else return undefined;
}

const sessions = {lister, creer, close};
export default sessions;